import * as Yup from "yup"

export const signUpSchema = Yup.object().shape({
    guestMode: Yup.boolean(),
    name: Yup.string().when("guestMode", {
        is: false,
        then: Yup.string().required("Required"),
    }),
    email: Yup.string()
        .email("Invalid email")
        .when("guestMode", {
            is: false,
            then: Yup.string().required("Required"),
        }),
    password: Yup.string().when("guestMode", {
        is: false,
        then: Yup.string().min(8, "Minimum eight characters").required("Required"),
    }),
})
