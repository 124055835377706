import { useDispatch } from "react-redux"
import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import { FORGOT_PASSWORD_MODAL, SIGN_IN_MODAL, SIGN_UP_MODAL } from "../../../../constants/modals"
import ForgotPasswordModal from "../ForgotPasswordModal"
import { signInSchema } from "./SignInModal.schema"
import { Field, Form, Formik, FormikHelpers } from "formik"
import { loginActions } from "../../../../redux/actions/user/user"
import { StyledSignInModal } from "./SignInModal.styles"
import Input from "../../../../reusableComponents/Formik/Input"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { StyledBodyMain, StyledLink } from "../../../../styles/Text/Text.styles"
import SignUpModal from "../SignUpModal"
import InputPassword from "../../../../reusableComponents/Formik/InputPassword"
import { errorCodes } from "../../../../constants/errorCodes"
import { EmailNotConfirmedErrorMessage } from "./EmailNotConfirmedErrorMessage"
import SSO from "../SSO"
import Divider from "../Divider"

const initialValues = {
    email: "",
    password: "",
}

type FormValues = {
    email: string
    password: string
    errorCode?: number
}

const SignInModal = () => {
    const dispatch = useDispatch()

    const openForgoPasswordModal = () => {
        dispatch(removeModal(SIGN_IN_MODAL))
        dispatch(
            addModal({
                id: FORGOT_PASSWORD_MODAL,
                hideCross: true,
                children: <ForgotPasswordModal />,
                size: "auto",
            })
        )
    }

    const openSignUpModal = () => {
        dispatch(removeModal(SIGN_IN_MODAL))
        dispatch(addModal({ id: SIGN_UP_MODAL, children: <SignUpModal />, size: "auto" }))
    }

    const onSubmit = (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(loginActions.request({ ...values, setErrors }))
    }

    const onCancel = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault()
        dispatch(removeModal(SIGN_IN_MODAL))
    }

    return (
        <StyledSignInModal>
            <div className="left">
                <StyledBodyMain weight={600}>Log into your account with:</StyledBodyMain>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={signInSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form className="form">
                        <div className="mainContent">
                            <SSO />
                            <Divider />
                            <div className="formContent">
                                <div className="fieldMargin block">
                                    <Field
                                        type="email"
                                        name="email"
                                        as={Input}
                                        placeholder="Email"
                                        block
                                    />
                                    <CustomErrorMessage name="email" component="div" />
                                    {errors?.errorCode !== undefined &&
                                        +errors?.errorCode === errorCodes.EMAIL_NOT_CONFIRMED && (
                                            <EmailNotConfirmedErrorMessage email={values.email} />
                                        )}
                                </div>
                                <div className="block">
                                    <Field
                                        type="password"
                                        name="password"
                                        as={InputPassword}
                                        placeholder="Password"
                                        block
                                    />
                                    <CustomErrorMessage name="password" component="div" />
                                </div>
                            </div>
                        </div>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} type="button">
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary disabled={!dirty} type="submit">
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="linkBlock">
                <StyledLink onClick={openForgoPasswordModal}>Forgot your password?</StyledLink>
                <StyledBodyMain>
                    Don't have an account?{" "}
                    <StyledLink onClick={openSignUpModal}>Sign up now!</StyledLink>
                </StyledBodyMain>
            </div>
        </StyledSignInModal>
    )
}

export default SignInModal
