import { ReactFacebookLoginInfo, ReactFacebookLoginProps } from "react-facebook-login"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { setUserAction, ssoSignOnActions } from "../../../../redux/actions/user/user"
import { useDispatch, useSelector } from "react-redux"
import { StyledSSO } from "./SSO.styles"
import Image from "next/image"
import { removeAllModal } from "../../../../redux/actions/ui/ui"
import { AppState } from "../../../../redux/reducers"

const SSO = () => {
    const dispatch = useDispatch()

    const action = useSelector((state: AppState) => state.ui.action)

    const twitterLogin = () => {
        const width = 450,
            height = 730,
            left = window.screen.width / 2 - width / 2,
            top = window.screen.height / 2 - height / 2

        window.open(
            process.env.NEXT_PUBLIC_APP_API_URL + "auth/twitter",
            "Twitter",
            "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
        )

        const onStorageChanged = () => {
            const user = window.localStorage.getItem("user")
            if (!user) return

            dispatch(setUserAction(JSON.parse(user)))
            dispatch(removeAllModal())
            window.localStorage.removeItem("user")
            window.removeEventListener("storage", onStorageChanged)
            action && action()
        }

        window.addEventListener("storage", onStorageChanged)
    }

    const ssoSignOn = (token: string) => {
        dispatch(ssoSignOnActions.request({ token, type: "facebook" }))
    }

    return (
        <StyledSSO>
            <FacebookLogin
                appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_CLIENT_ID || ""}
                callback={({ accessToken }: ReactFacebookLoginInfo) => {
                    ssoSignOn(accessToken)
                }}
                isMobile={false}
                render={(renderProps: ReactFacebookLoginProps) => (
                    <button
                        onClick={(e) => {
                            renderProps.onClick && renderProps.onClick(e as any)
                        }}
                        className="facebook"
                        type="button"
                    >
                        <Image
                            width="20"
                            height="20"
                            src="/icons/social/facebookBlue.svg"
                            alt="facebook"
                            className="image"
                        />
                        <span>Facebook</span>
                    </button>
                )}
            />
            <button onClick={twitterLogin} className="twitter" type="button">
                <Image
                    width="20"
                    height="20"
                    src="/icons/social/twitter.svg"
                    alt="twitter"
                    className="image"
                />
                <span>Twitter</span>
            </button>
            <div className="textMessage">
                We hate spam on social networks, so we won’t publish anything without your
                permission.
            </div>
        </StyledSSO>
    )
}

export default SSO
