import styled from "styled-components"

export const StyledSignInModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mainContent {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .formContent {
    width: 286px;
  }

  .block {
    width: 100%;
  }

  .fieldMargin {
    margin-bottom: 20px;
  }

  .buttonsBlock {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 50%;

    & > * {
      width: 45%;
    }
  }

  .linkBlock {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

    * {
      font-size: 16px;
    }
  }

  .left {
    display: flex;
    width: 100%;
  }

  @media (max-width: 790px) {
    form,
    .mainContent {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .buttonsBlock {
      width: 100%;
    }


  }

  @media (max-width: 575px) {
    .formContent {
      width: 100%;
    }

`

export const StyledError = styled.div`
    .link {
        cursor: pointer;
    }
`
