import { Field, Form, Formik, FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { resetPasswordActions } from "../../../../redux/actions/user/user"
import { StyledForgotPasswordModal } from "./ForgotPasswordModal.styles"
import { StyledBodyMain } from "../../../../styles/Text/Text.styles"
import Input from "../../../../reusableComponents/Formik/Input"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import { FORGOT_PASSWORD_MODAL, SIGN_IN_MODAL } from "../../../../constants/modals"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import { forgotPasswordSchema } from "./ForgotPassword.schema"
import { errorCodes } from "../../../../constants/errorCodes"
import { EmailNotConfirmedErrorMessage } from "./EmailNotConfirmedErrorMessage"
import SignInModal from "../SignInModal"

type FormValues = {
    email: string
    errorCode?: null
}

const ForgotPasswordModal = () => {
    const dispatch = useDispatch()

    const onSubmit = (value: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(resetPasswordActions.request({ ...value, setErrors }))
    }

    const onCancel = () => {
        dispatch(removeModal(FORGOT_PASSWORD_MODAL))
        dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    }

    return (
        <StyledForgotPasswordModal>
            <StyledBodyMain weight={600}>
                Enter your email address to reset your password.
            </StyledBodyMain>
            <Formik
                initialValues={{ email: "" }}
                validationSchema={forgotPasswordSchema}
                onSubmit={onSubmit}
            >
                {({ errors, values, isValid, dirty }) => (
                    <Form>
                        <div className="block">
                            <Field type="email" name="email" as={Input} placeholder="Email" block />
                            <CustomErrorMessage name="email" component="div" />
                            {errors?.errorCode !== undefined &&
                                +errors?.errorCode === errorCodes.EMAIL_NOT_CONFIRMED && (
                                    <EmailNotConfirmedErrorMessage email={values.email} />
                                )}
                        </div>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} block>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary type="submit" disabled={!(isValid && dirty)} block>
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledForgotPasswordModal>
    )
}

export default ForgotPasswordModal
