import styled from "styled-components"

export const StyledForgotPasswordModal = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    form {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 400px;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > * {
            width: 45%;
        }
    }

    .block {
        width: 100%;
    }

    @media (max-width: 575px) {
        form {
            width: 100%;
        }
    }
`

export const StyledError = styled.div`
    .link {
        cursor: pointer;
    }
`
