import styled from "styled-components"

export const StyledSignUpModal = styled.div`
  display: flex;

  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .fieldMargin {
    margin-bottom: 20px;
  }

  .mainContent {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .buttonsBlock {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 50%;

    & > * {
      width: 45%;
    }
  }

  .block {
    width: 100%;
  }

  .signInLinkWrapper {
    display: flex;
    justify-content: center;
  }

  .signInLink {
    margin-top: 30px;
    font-size: 16px;
  }

  .formContent {
    width: 286px;
  }

  .alignEnd {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  @media (max-width: 790px) {
    .form,
    .mainContent {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .buttonsBlock {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .formContent {
      width: 100%;
    }
`

export const StyledError = styled.div`
    .link {
        cursor: pointer;
    }
`
