import { Field, Form, Formik, FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { signUpActions } from "src/redux/actions/user/user"
import { signUpSchema } from "./SignUp.schema"
import { addModal, removeModal, setHideHeader } from "src/redux/actions/ui/ui"
import { SIGN_IN_MODAL, SIGN_UP_MODAL } from "src/constants/modals"
import { errorCodes } from "src/constants/errorCodes"
import { EmailNotConfirmedErrorMessage } from "./EmailNotConfirmedErrorMessage"
import SSO from "../SSO"
import Divider from "../Divider"
import * as random from "random-name"
import { generateNumber } from "src/helpers"

// components
import CustomErrorMessage from "src/reusableComponents/CustomErrorMessage"
import ButtonPrimary from "src/reusableComponents/ButtonPrimary"
import ButtonOutlined from "src/reusableComponents/ButtonOutlined"
import SignInModal from "../SignInModal"
import InputPassword from "src/reusableComponents/Formik/InputPassword"
import Checkbox from "src/reusableComponents/Formik/Checkbox"
import Input from "src/reusableComponents/Formik/Input"

// styles
import { StyledBodyMain, StyledLink } from "src/styles/Text/Text.styles"
import { StyledSignUpModal } from "./SignUpModal.styles"

type FormValues = {
    name: string
    email: string
    password: string
    guestMode: boolean
    errorCode?: number
}

type Props = {
    withGuestMode?: boolean
}

const SignUpModal = ({ withGuestMode = false }: Props) => {
    const suggestedName = `${random.first()}${generateNumber(4)}`
    const initialValues: FormValues = {
        name: "",
        email: "",
        password: "",
        guestMode: false,
    }

    const dispatch = useDispatch()

    const onSubmit = (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(
            signUpActions.request({ ...values, name: values.name || suggestedName, setErrors })
        )
        dispatch(setHideHeader({ hideHeader: false }))
    }

    const onSignInClick = () => {
        dispatch(removeModal(SIGN_UP_MODAL))
        dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    }

    const onCancel = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault()
        dispatch(removeModal(SIGN_UP_MODAL))
    }

    return (
        <StyledSignUpModal>
            <StyledBodyMain weight={600}>Sign up with:</StyledBodyMain>

            <Formik
                initialValues={initialValues}
                validationSchema={signUpSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form className="form">
                        <div className="mainContent">
                            <SSO />
                            <Divider />
                            <div className="alignEnd">
                                <div className="formContent">
                                    <div className="fieldMargin block">
                                        <Field
                                            type="name"
                                            name="name"
                                            as={Input}
                                            placeholder={
                                                values.guestMode
                                                    ? `Guest name (${suggestedName})`
                                                    : "Name"
                                            }
                                            block
                                        />
                                        <CustomErrorMessage name="name" component="div" />
                                    </div>
                                    {!values.guestMode && (
                                        <>
                                            <div>
                                                <div className="fieldMargin block">
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        as={Input}
                                                        placeholder="Email"
                                                        block
                                                    />
                                                    <CustomErrorMessage
                                                        name="email"
                                                        component="div"
                                                    />

                                                    {errors?.errorCode !== undefined &&
                                                        +errors?.errorCode ===
                                                            errorCodes.EMAIL_NOT_CONFIRMED && (
                                                            <div>
                                                                <EmailNotConfirmedErrorMessage
                                                                    email={values.email}
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="block">
                                                    <Field
                                                        type="password"
                                                        name="password"
                                                        as={InputPassword}
                                                        placeholder="Password"
                                                        block
                                                    />
                                                    <CustomErrorMessage
                                                        name="password"
                                                        component="div"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {withGuestMode && (
                                    <div className="block">
                                        <Field
                                            name="guestMode"
                                            as={Checkbox}
                                            label="Post as guest"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} block type="button">
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary disabled={!dirty} type="submit" block>
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="signInLinkWrapper">
                <StyledBodyMain className="signInLink">
                    Already have an account?{" "}
                    <StyledLink onClick={onSignInClick} className="signInLink">
                        Sign in here
                    </StyledLink>
                </StyledBodyMain>
            </div>
        </StyledSignUpModal>
    )
}

export default SignUpModal
