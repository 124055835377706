import { ENTER_CODE_MODAL, SIGN_UP_MODAL } from "../../../../constants/modals"
import { useDispatch } from "react-redux"
import { addModal, removeAllModal } from "../../../../redux/actions/ui/ui"
import EnterCodeModal from "../EnterCodeModal"
import SignUpModal from "../SignUpModal"
import { StyledBodyMain } from "../../../../styles/Text/Text.styles"
import { StyledEmailSentModal } from "./EmailSentModal.styles"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { useResendEmail } from "../../../../hooks/useResendEmail"

type Props = { message: React.ReactNode; email: string }

const EmailSentModal = ({ message, email }: Props) => {
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(removeAllModal())
        dispatch(addModal({ id: SIGN_UP_MODAL, children: <SignUpModal />, size: "auto" }))
    }
    const onEnterCodeClick = () => {
        dispatch(removeAllModal())
        dispatch(
            addModal({
                id: ENTER_CODE_MODAL,
                children: <EnterCodeModal email={email} />,
                hideCross: true,
                size: "auto",
            })
        )
    }

    const resendConfirmationComponent = useResendEmail(email)

    return (
        <StyledEmailSentModal>
            <StyledBodyMain>
                {message}
                {resendConfirmationComponent}
            </StyledBodyMain>

            <div className="buttonsBlock">
                <ButtonOutlined onClick={closeModal} type="button" block>
                    Cancel
                </ButtonOutlined>
                <ButtonPrimary onClick={onEnterCodeClick} block>
                    Enter code
                </ButtonPrimary>
            </div>
        </StyledEmailSentModal>
    )
}

export default EmailSentModal
