import styled from "styled-components"

export const StyledDivider = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    font-style: italic;
    font-weight: 700;
    font-size: 16px;

    margin: 0 20px;

    &:before {
        content: "";
        height: 40%;
        width: 0;
        position: absolute;
        left: 50%;
        border-left: 1px solid #656464;
        top: 0;
    }
    &:after {
        content: "";
        height: 40%;
        width: 0;
        position: absolute;
        left: 50%;
        border-left: 1px solid #656464;
        bottom: 0;
    }

    @media (max-width: 790px) {
        flex-direction: row;
        margin-bottom: 20px;

        &:before {
            width: 40%;
            height: 0;
            left: 0;
            top: 50%;
            border-left: 0;
            border-bottom: 1px solid #656464;
        }

        &:after {
            width: 40%;
            height: 0;
            right: 0;
            top: 50%;
            left: unset;
            border-left: 0;
            border-bottom: 1px solid #656464;
        }
    }
`
