import { StyledInputPassword, StyledIconButton, StyledRow } from "./InputPassword.styles"
import React, { useState } from "react"
import Image from "next/image"

const InputPassword = (props: any) => {
    const [showPassword, setShowPassword] = useState(props.showPassword || false)
    return (
        <StyledRow>
            <StyledInputPassword
                {...props}
                type={showPassword ? "text" : "password"}
            ></StyledInputPassword>
            <StyledIconButton>
                <Image
                    src={showPassword ? "/icons/showPassword.svg" : "/icons/hidePassword.svg"}
                    width={showPassword ? 26 : 26}
                    height={showPassword ? 16 : 26}
                    onClick={() => setShowPassword(!showPassword)}
                />
            </StyledIconButton>
        </StyledRow>
    )
}

export default InputPassword
