import { useDispatch } from "react-redux"
import { removeModal } from "../../../../redux/actions/ui/ui"
import { ENTER_CODE_MODAL } from "../../../../constants/modals"
import { sendConfirmCodeActions } from "../../../../redux/actions/user/user"
import { Field, Form, Formik, FormikHelpers } from "formik"
import { enterCodeSchema } from "./EnterCode.schema"
import { StyledEnterCodeModal } from "./EnterCodeModal.styles"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { StyledBodyMain } from "../../../../styles/Text/Text.styles"
import Input from "../../../../reusableComponents/Formik/Input"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import { useResendEmail } from "../../../../hooks/useResendEmail"

type Props = {
    email: string
}

type FormValues = {
    confirmCode: string
}

const initialValues: FormValues = { confirmCode: "" }

const EnterCodeModal = ({ email }: Props) => {
    const dispatch = useDispatch()

    const onCancel = () => dispatch(removeModal(ENTER_CODE_MODAL))
    const onSubmit = (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(sendConfirmCodeActions.request({ ...values, email, setErrors }))
    }

    const resendConfirmationComponent = useResendEmail(email)

    return (
        <StyledEnterCodeModal>
            <StyledBodyMain>
                In order to login with your new Rootclaim account, you must confirm your email
                address. Enter the code you received in your confirmation email:
            </StyledBodyMain>
            <div className="margin">
                <StyledBodyMain weight={700}>{email}</StyledBodyMain>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={enterCodeSchema}
                onSubmit={onSubmit}
            >
                {() => (
                    <Form>
                        <div>
                            <Field type="text" name="confirmCode" as={Input} placeholder="Code" />
                            <CustomErrorMessage name="confirmCode" component="div" />
                        </div>
                        <div className="margin">{resendConfirmationComponent}</div>
                        <div className="buttonsBlock">
                            <ButtonOutlined onClick={onCancel} type="button" block>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary type="submit" block>
                                Continue
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledEnterCodeModal>
    )
}

export default EnterCodeModal
