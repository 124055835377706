import { StyledSubText } from "../../../../styles/Text/Text.styles"
import { StyledError } from "./SignUpModal.styles"
import { useResendModal } from "../../../../hooks/useResendEmail"

type Props = {
    email: string
}

export const EmailNotConfirmedErrorMessage = ({ email }: Props) => {
    const [openResendModal] = useResendModal(email)
    return (
        <StyledError>
            <StyledSubText color="red">
                You have to{" "}
                <StyledSubText
                    color="blue"
                    weight={600}
                    inline
                    className="link"
                    onClick={openResendModal}
                >
                    confirm
                </StyledSubText>{" "}
                your account before continuing
            </StyledSubText>
        </StyledError>
    )
}
