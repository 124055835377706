import { EMAIL_SENT_MODAL, RESENT_EMAIL_MODAL } from "../../../../constants/modals"
import EmailSentModal from "./EmailSentModal"
import { StyledBodyMain } from "../../../../styles/Text/Text.styles"
import { ModalPropsType } from "../../../../redux/actions/ui/types"

export const addEmailModal = (email: string): ModalPropsType => ({
    id: EMAIL_SENT_MODAL,
    children: (
        <EmailSentModal
            email={email}
            message={
                <>
                    A verification email has been sent to{" "}
                    <StyledBodyMain weight={700} inline>
                        {email}
                    </StyledBodyMain>{" "}
                    Please click the link given in that email to complete the sign-up process.
                </>
            }
        />
    ),
    hideCross: true,
    size: "auto",
})

export const addEmailResentModal = (email: string): ModalPropsType => ({
    id: RESENT_EMAIL_MODAL,
    children: (
        <EmailSentModal
            email={email}
            message={
                <>
                    Email has been resent to{" "}
                    <StyledBodyMain weight={700} inline>
                        {email}
                    </StyledBodyMain>
                </>
            }
        />
    ),
    hideCross: true,
    size: "auto",
})
