import styled from "styled-components"

export const StyledEnterCodeModal = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .margin {
        margin-top: 20px;
    }

    form {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        width: 80%;
        min-width: 300px;
        justify-content: space-between;

        & > * {
            width: 45%;
        }
    }
`
