import styled from "styled-components"

export const StyledSSO = styled.div`
    .facebook,
    .twitter {
        border: none;
        border-radius: 2px;

        padding: 14px 20px;
        width: 100%;
        display: flex;
        align-items: start;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;

        color: #ffffff;

        cursor: pointer;

        margin-bottom: 20px;

        width: 286px;

        span {
            margin-left: 16px;
        }
    }

    .facebook {
        background: #3f5089;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.111096);
    }

    .twitter {
        background: #5297cd;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.111096);
    }

    .textMessage {
        font-size: 14px;
        line-height: 20px;
        width: 286px;
    }

    @media (max-width: 790px) {
        .textMessage {
            display: none;
        }
        .facebook,
        .twitter {
            width: 100%;
        }
    }
`
