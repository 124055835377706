import { useResendModal } from "../../../../hooks/useResendEmail"
import { StyledError } from "./SignInModal.styles"
import { StyledSubText } from "../../../../styles/Text/Text.styles"

type Props = {
    email: string
}

export const EmailNotConfirmedErrorMessage = ({ email }: Props) => {
    const [openResendModal] = useResendModal(email)
    return (
        <StyledError>
            <StyledSubText color="red">
                You have to your{" "}
                <StyledSubText
                    color="blue"
                    weight={600}
                    inline
                    className="link"
                    onClick={openResendModal}
                >
                    confirm{" "}
                </StyledSubText>
                account before continuing.
            </StyledSubText>
        </StyledError>
    )
}
