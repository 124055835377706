import { useDispatch } from "react-redux"
import { resendConfirmEmailActions } from "../redux/actions/user/user"
import { StyledBodyMain } from "../styles/Text/Text.styles"
import { StyledResendEmailBlock } from "../styles/resendEmailBlock.styles"
import { addModal, removeAllModal } from "../redux/actions/ui/ui"
import { addEmailResentModal } from "../components/Header/Modals/EmailSentModal/helpers"

export const useResendEmail = (email: string) => {
    const dispatch = useDispatch()

    const resendEmail = () => {
        dispatch(resendConfirmEmailActions.request({ email }))
    }

    const resendConfirmationComponent = (
        <StyledResendEmailBlock>
            <StyledBodyMain className="marginTop">
                Didn't get the email?{" "}
                <StyledBodyMain
                    weight={700}
                    inline
                    color="blue"
                    className="resendLink"
                    onClick={resendEmail}
                >
                    Resend.
                </StyledBodyMain>
            </StyledBodyMain>
        </StyledResendEmailBlock>
    )

    return [resendConfirmationComponent]
}

export const useResendModal = (email: string) => {
    const dispatch = useDispatch()

    const openResendModal = () => {
        dispatch(removeAllModal())
        dispatch(addModal(addEmailResentModal(email)))
        dispatch(resendConfirmEmailActions.request({ email }))
    }

    return [openResendModal]
}
