import { StyledCheckbox } from "./Checkbox.styles"

type Props = {
    label: string
}

const Checkbox = ({ label, ...restProps }: Props) => {
    return (
        <StyledCheckbox>
            <label className="radioContainer">
                {label}

                <input type="checkbox" {...restProps} />
            </label>
        </StyledCheckbox>
    )
}

export default Checkbox
