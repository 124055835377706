import styled from "styled-components"
import { colors } from "../../../constants/colors"

type Props = {
    block?: boolean
}

export const StyledInputPassword = styled.input<Props>`
    padding: 14px 49px 14px 20px;

    width: ${({ block }) => (block ? "100%" : "unset")};

    border: 1px solid ${colors.lightGrey};
    box-sizing: border-box;
    border-radius: 5px;

    &:focus {
        outline: none;
        border: 1px solid ${colors.grey};
    }
`

export const StyledIconButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 10px;
    margin: auto;
    padding-top: 3px;
`

export const StyledRow = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    width: auto;
`
